import { Collapse, CollapseProps } from 'react-collapse';
import { useMemo } from 'react';

type Props = CollapseProps & {
  className?: string
}

function CollapseWithClassName(props: Props) {
  const { className: collapse, ...restProps } = props;
  const theme = useMemo(
    () => ({
      collapse,
      content: 'ReactCollapse--content',
    }),
    [collapse],
  );

  return <Collapse {...restProps} theme={theme} />;
}

export default CollapseWithClassName
