import React, { PureComponent, createRef } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { navbarHeight } from 'utils/sizes';
import rem from 'utils/rem';
import Link from 'components/Link';

import NavLinks from './NavLinks';
import Logo from './Logo';
import MobileNavbar from './MobileNavbar';

type WrapBaseProps = {
  transparent?: boolean
}

const Wrapper = styled.nav<WrapBaseProps>`
  ${({ transparent }) => css`
    background: ${transparent ? 'transparent' : 'white'};
    box-shadow: ${transparent ? 'none' : '0px 0px 20px rgba(47,53,63,0.05)'} ;
    color: ${transparent ? 'white' : '#323a4d'};
  `}
  box-sizing: border-box;
  font-size: ${rem(15)};
  font-weight: 500;
  height: ${rem(navbarHeight)};
  padding: 0;
  position: fixed;
  transition: background 300ms ease-out;
  z-index: 3;
`;

const StartWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const EndWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;


const NormalNavbar = styled.div`
  display: flex;
  align-items: center;
  padding: 0 ${rem(20)};
  justify-content: space-between;
`;

const LogoLink = styled(Link)`
  display: inline-block;
  vertical-align: center;
  margin-right: ${rem(35)};
`;

type Props = {
  onSideToggle: () => void
  onMobileNavToggle: () => void
  isSideFolded: boolean
  isMobileNavFolded: boolean
  showSideNav: boolean
  transparent: boolean
}

type State = {
  isOpen: boolean
}

class Navbar extends PureComponent<Props, State> {
  state = {
    isOpen: false,
  };
  openModal = () => this.setState(() => ({ isOpen: true }));
  closeModal = () => this.setState(({ isOpen }) => (isOpen ? { isOpen: false } : null));
  render() {
    const {
      onSideToggle,
      onMobileNavToggle,
      isSideFolded,
      isMobileNavFolded,
      showSideNav,
      transparent,
    } = this.props;

    return (
      <Wrapper transparent={transparent}>
        <NormalNavbar>
          <StartWrapper>
            <LogoLink unstyled href="/">
              <Logo mode={transparent ? 'light' : 'dark'} />
            </LogoLink>
            <NavLinks />
          </StartWrapper>
          <EndWrapper>
            hello world
          </EndWrapper>
        </NormalNavbar>
        <MobileNavbar
          isSideFolded={isSideFolded}
          onSearchButtonClick={this.openModal}
          isMobileNavFolded={isMobileNavFolded}
          onSideToggle={onSideToggle}
          onMobileNavToggle={onMobileNavToggle}
          showSideNav={showSideNav}
        />
      </Wrapper>
    );
  }
}

export default Navbar;
