import React from 'react';
import { css } from '@emotion/react';
import { Container, Image, Grid, Box, Text } from 'theme-ui';

const partners = [
  {
    name: 'tiki',
    image: require('./partner-tiki.png'),
  },
  {
    name: 'lazada',
    image: require('./partner-lazada.png'),
  },
  {
    name: 'shopee',
    image: require('./partner-shopee.png'),
  },
  {
    name: 'vinid',
    image: require('./partner-vinid.png'),
  },
  {
    name: 'huunghi',
    image: require('./partner-huunghi.png'),
  },
  {
    name: 'dhl',
    image: require('./partner-dhl.png'),
  },
  {
    name: 'kerry',
    image: require('./partner-kerry.png'),
  },
  {
    name: 'ninjavan',
    image: require('./partner-ninjavan.png'),
  },
  {
    name: 'ghn',
    image: require('./partner-ghn.png'),
  },
  {
    name: 'minigood',
    image: require('./partner-minigood.png'),
  },
];

const title = css`
  font-size: 24px;
  font-weight: bold;
  padding-bottom: 30px;
`;

const titleMobile = css`
  font-size: 34px;
  font-weight: bold;
  margin-bottom: 60px;
`;

const partnersWrap = css`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;

function Partners() {
  return (
    <Container bg="white" pt={120} pb={80}>
      <Text
        variant="landing"
        css={titleMobile}
        sx={{
          display: ['block', 'block', 'none'],
        }}
      >
        Đối tác uy tín
      </Text>
      <Grid columns={[1, 1, 2]}>
        <Box>
          <Text
            variant="landing"
            css={title}
            sx={{
              display: ['none', 'none', 'block'],
            }}
          >
            Đối tác uy tín
          </Text>
          <Text>
            Việc Có luôn nỗ lực kết nối cùng các đối tác uy tín để cung cấp những công việc có mức
            thù lao cao cùng môi trường làm việc chuyên nghiệp.
          </Text>
        </Box>
        <Box
          css={partnersWrap}
          sx={{
            gridColumn: [1, 1, 2],
            gridRow: 1,
            marginBottom: [40, 40, 0],
          }}
        >
          <Grid columns={[partners.length, partners.length, partners.length / 2]}>
            {partners.map((item) => (
              <Image key={item.name} alt={item.name} src={item.image} variant="partner" />
            ))}
          </Grid>
        </Box>
      </Grid>
    </Container>
  );
}

export default Partners;
