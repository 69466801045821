import Head from 'next/head'

import Nav from 'components/Nav'
import WithIsScrolled from 'components/WithIsScrolled'
import { useState, useCallback } from 'react'
import Banner from 'components/Banner'
import Benefits from 'components/Home/Benefits'
import Partners from 'components/Partners'
import Footer from 'components/Footer'


export default function Home() {
  const [isMobileNavFolded, setIsMobileNavFolded] = useState(false)
  const toggleMobileNav = useCallback(() => {
    setIsMobileNavFolded(s => !s)
  }, [])
  const onRouteChange = useCallback(() => {
    setIsMobileNavFolded(true)
  }, [])

  return (
    <>
      <Head>
        <title>Create Next App</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <WithIsScrolled>
        {({ isScrolled }) => (
          <Nav
            isMobileNavFolded={isMobileNavFolded}
            onMobileNavToggle={toggleMobileNav}
            onRouteChange={onRouteChange}
            showSideNav={false}
            transparent={!isScrolled}
          />
        )}
      </WithIsScrolled>
      <Banner
        image={require('components/Home/banner.jpg')}
        imageWebp={require('components/Home/banner.jpg?webp')}
      />
      <Benefits/>
      <Partners/>
      <Footer/>
    </>
  )
}
