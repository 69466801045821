import { css, Box, AspectImage, Text } from 'theme-ui';
import Button from 'components/Button';
import Link from 'next/link';
import styled from '@emotion/styled';
import { ReactNode } from 'react';

type Props = {
  action: ReactNode
  href: string;
  title: string;

  image?: string;
  imageWebp?: string;
};

const container = css({
  backgroundColor: '#d5dae1',
  backgroundImage: ['unset', `url(${require('./decor-2.svg')})`],
  backgroundPositionX: 'right',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  borderRadius: '6px',
  marginTop: [3, 120],
  paddingBottom: 60,
  paddingLeft: 65,
  paddingRight: 65,
  paddingTop: 60,
  position: 'relative',
});

const A = styled('a')(
  css({
    display: ['flex', 'inline-flex'],
    backgroundColor: '#323A4D',
    borderRadius: '6px',
    color: '#fff',
    fontWeight: 'bold',
    marginTop: 20,
    paddingBottom: 12,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 12,
    justifyContent: 'center',
    ':hover': {
      backgroundColor: '#545a65',
    },
  }),
);

const Img = styled.picture(
  css({
    height: 330,
    display: ['none', 'block'],
    position: 'absolute',
    bottom: 0,
    right: '6%',
  }),
);

function Base(props: Props) {
  return (
    <Box css={container}>
      <Box sx={{ maxWidth: ['100%', '254px'] }}>
        <Text
          sx={{
            color: '#2F353F',
            fontSize: [24],
            fontWeight: 'bold',
            lineHeight: ['34px'],
            marginBottom: 20,
            textAlign: ['center', 'left'],
          }}
        >
          {props.title}
        </Text>
        <Link href={props.href}>
          <A>{props.action}</A>
        </Link>
      </Box>
      <Img>
        <source srcSet={props.imageWebp} type="image/webp" />
        <img height={330} src={props.image} />
      </Img>
    </Box>
  );
}

export default Base;
