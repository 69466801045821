import React from 'react'
import { Container, Flex, Grid, Box, Styled, Text } from 'theme-ui'
import { css } from '@emotion/react'

const benefits = [
  {
    color: '#f5a337',
    description: 'Tuyển được người chỉ sau 4h. Từ bây giờ việc tìm kiếm nhân sự ngắn hạn sẽ không còn là vấn đề của bạn nữa.',
    image: require('./employer-benefit-1.svg'),
    title: 'Tìm người nhanh chóng',
  },
  {
    color: '#37c994',
    description: 'Tạm biệt giấy tờ phức tạp. Mọi công việc chấm công, tính lương, trả lương đều được tự động hoá thông qua nền tảng Việc Có.',
    image: require('./employer-benefit-2.svg'),
    title: 'Chấm công & tính phí',
  },
  {
    color: '#374fc7',
    description: 'Đội chăm sóc khách hàng 24/7 luôn sẵn sàng hỗ trợ bạn để giải quyết các vấn đề cấp bách, giúp công việc của bạn luôn trôi chảy.',
    image: require('./employer-benefit-3.svg'),
    title: 'Hỗ trợ 24/7',
  },
]

const column = css`
  flex-direction: column;
  justify-content: center;
  text-align: center;
`

const title = css`
  font-size: 20px;
  font-weight: bold;
`

function Benefits() {
  return (
    <Container bg="white" pt={120} pb={120} id="next">
      <Grid columns={[1,3]}>
        {benefits.map((s, idx) => (
          <Flex css={column} color={s.color} key={`${idx}`}>
            <Styled.img sx={{
              paddingBottom: [20],
            }} src={s.image}/>
            <Text pb={10} css={title}>{s.title}</Text>
            <Box color="#898f9a">
              {s.description}
            </Box>
          </Flex>
        ))}
      </Grid>
    </Container>
  )
}

export default Benefits
