import styled from '@emotion/styled';
import UnstyledLink, { LinkProps } from 'next/link';

import rem from 'utils/rem';
import { red, violetRed, lightGrey } from 'utils/colors';

export const StyledLink = styled.a`
  display: inline-block;
  color: inherit;
  cursor: pointer;
  padding: ${rem(2)} ${rem(8)};
  margin: ${rem(-2)} ${rem(-8)};

  @media (min-width: ${1000 / 16}em) {
    border-radius: ${rem(3)};

    &:hover {
      background: ${lightGrey};
    }
  }
`;

export const InlineLink = styled.a`
  cursor: pointer;
  text-decoration: underline;

  &:hover {
  }
`;

type Props = React.PropsWithChildren<LinkProps & {
  'aria-label'?: string
  className?: string
  inline?: boolean
  unstyled?: boolean
  white?: boolean
}>

const Link = ({ ['aria-label']: ariaLabel, children, className, inline, unstyled, white, ...rest }: Props) => {
  let Child = StyledLink;
  if (inline) {
    Child = InlineLink;
  } else if (unstyled) {
    // @ts-ignore
    Child = 'a';
  }

  let dataAttrs;
  if (white) {
    dataAttrs = { 'data-white': white };
  }

  return (
    <UnstyledLink {...rest}>
      <Child rel="noopener" target="_blank" aria-label={ariaLabel} href={rest.href} className={className} {...dataAttrs}>
        {children}
      </Child>
    </UnstyledLink>
  );
};

export default Link;
