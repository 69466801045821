import styled from '@emotion/styled';
import { css } from '@emotion/react';

type BaseProps = {
  mode?: 'dark' | 'light'
}

const s = (p: BaseProps) => css`
  background-image: url(${p.mode === 'dark' ? require('./logo-no-slogan-dark.svg') : require('./logo-no-slogan-light.svg')});
`

const Logo = styled.div<BaseProps>`
  ${s}
  display: inline-block;
  vertical-align: middle;
  box-sizing: border-box;

  background-position: center;
  background-size: contain;
  width: 90px;
  height: 18px;
`;

export default Logo;
