import { Box } from 'theme-ui';

import SafeA from 'components/SafeA';

function BoCongThuong(props) {
  return (
    <SafeA href={'http://www.online.gov.vn/WebsiteDisplay.aspx?DocId=50964'}>
      <Box {...props}>
        <picture>
          <source srcSet={require('./bocongthuong.png?webp')} type="image/webp" />
          <img height={38} src={require('./bocongthuong.png')}/>
        </picture>
      </Box>
    </SafeA>
  );
}

export default BoCongThuong;
