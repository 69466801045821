import { useState, useCallback } from 'react';

import { Context } from './Section'
import { useBreakpointIndex } from './useBreakpointIndex';

type ProviderProps = React.PropsWithChildren<{
  initialKey?: string;
}>;

function Provider(props: ProviderProps) {
  const enableCollapser = useBreakpointIndex() === 0;
  const [activeKey, setActiveKey] = useState(props.initialKey ?? null);
  const onActive = useCallback((k) => {
    setActiveKey(k);
  }, []);

  return (
    <Context.Provider
      value={{
        activeKey,
        enableCollapser,
        onActive,
      }}
    >
      {props.children}
    </Context.Provider>
  );
}

export default Provider
