import React from 'react'
import { DetailedHTMLProps, AnchorHTMLAttributes } from 'react';

type Props = Omit<
  DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>,
  'rel' | 'target'
>;

// Why we should use this component instead of `a` for external links?
// https://mathiasbynens.github.io/rel-noopener/
function SafeA(props: Props) {
  return <a {...props} rel="noopener noreferrer" target="_blank" />;
}

export default SafeA;
