import { keyframes } from "@emotion/react"
import styled from "@emotion/styled"

const click = keyframes`
  0% {
    top: 0;
  }
  50% {
    top: 10px;
  }
  100% {
    top: 0;
  }
`

const Jumper = styled.a`
  color: white;
  display: flex;
  margin-top: 15vh;
  text-decoration-color: white;
  text-decoration: none;
  &:before {
    animation: ${click} 1.5s linear infinite;
    background-image: url(${require('./arrow.svg')});
    content: ' ';
    display: block;
    height: 24px;
    margin-right: 5px;
    position: relative;
    width: 24px;
  }
  &:hover {
    color: #f5a337;
  }
`

export default Jumper
