import React, { useRef, useEffect, useState } from 'react'; // eslint-disable-line
import { findDOMNode } from 'react-dom';
import { useMediaQuery } from 'react-responsive'

let isMobile;
let lastWheelTimestamp;

if (typeof window !== 'undefined' && window.matchMedia) {
  isMobile = window.matchMedia(`(max-width: ${1000 / 16}em)`).matches;

  if (!isMobile) {
    window.addEventListener('wheel', ({ timeStamp }) => {
      lastWheelTimestamp = timeStamp;
    });
  }
}

const captureScroll = <P extends any>(Component: React.ComponentType<P>) => {
  if (isMobile) {
    return Component;
  }

  function CaptureScroll(props: P) {
    const ref = useRef(null)
    const isMobile = useMediaQuery({
      query: `(max-width: ${1000 / 16}em)`,
    })
    useEffect(() => {
      if (!isMobile) {
        return
      }

      const node = findDOMNode(ref.current) as Element;
      const onScroll = (evt: WheelEvent) => {
        // Don't access window wheel listener
        evt.stopImmediatePropagation();

        const { timeStamp, deltaY } = evt;
        const { offsetHeight, scrollHeight, scrollTop } = this.node;

        // If the window is being scrolled, don't scroll the captured scroll area
        if (timeStamp - lastWheelTimestamp <= 400) {
          lastWheelTimestamp = timeStamp;

          evt.preventDefault();
          window.scrollBy(0, deltaY);
          return;
        }

        const maxScrollTop = scrollHeight - offsetHeight;

        // Has the scroll area reached it's beginning/end
        const hasReachedTop = deltaY < 0 && scrollTop === 0;
        const hasReachedBottom = deltaY > 0 && scrollTop >= maxScrollTop;

        // Is the trajectory overshooting the scroll area
        const isReachingTop = scrollTop + deltaY <= 0;
        const isReachingBottom = scrollTop + deltaY >= maxScrollTop;

        if (hasReachedTop || hasReachedBottom || isReachingTop || isReachingBottom) {
          evt.preventDefault();
        }

        // If we're overshooting, we need to set the maximum available position
        if (isReachingTop || isReachingBottom) {
          node.scrollTop = isReachingTop ? 0 : maxScrollTop;
        }
      }

      node.addEventListener('wheel', onScroll);
      return () => {
        node.removeEventListener('wheel', onScroll);
      }
    }, [isMobile])

    return (<Component {...props} ref={ref} />)
  }

  return CaptureScroll;
};

export default captureScroll;
