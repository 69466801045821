import styled from "@emotion/styled";

const Icon = styled.img`
  height: 24px;
  width: 24px;
`;

const Link = styled.a`
  line-height: 0;
`

function Social(props) {
  return (
    <Link href={props.href} rel="noopener noreferrer" target="_blank">
      <Icon src={props.icon} />
    </Link>
  );
}

export default Social
