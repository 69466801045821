import { useState, useEffect, useCallback, useLayoutEffect } from 'react'
import { useThemeUI } from '@theme-ui/core'

const defaultBreakpoints = [40, 52, 64].map(n => n + 'em')

export const useBreakpointIndex = () => {
  const context = useThemeUI()
  const breakpoints = context?.theme?.breakpoints ?? defaultBreakpoints

  const getIndex = useCallback(() => {
    return breakpoints.filter(
      breakpoint =>
        window.matchMedia(`screen and (min-width: ${breakpoint})`).matches
    ).length
  }, [breakpoints])

  const [value, setValue] = useState(null)
  
  useEffect(() => {
    const onResize = () => {
      setValue(getIndex())
    }
    onResize();
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [breakpoints, getIndex])

  return value
}