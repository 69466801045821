import { css, Box, Flex, Container, Grid, Text } from 'theme-ui';
import Link from 'next/link';
import React from 'react';
import dynamic from 'next/dynamic';

import Social from './SocialLink';
import BoCongThuong from './BoCongThuong';
import SafeA from 'components/SafeA';
import Base from './Find/base';
import Section from './Section';
import SectionProvider from './SectionProvider'

const text = css({
  color: '#898F9A',
  fontSize: [16, 14],
  lineHeight: 1.75,
  marginTop: 15,
  textDecoration: 'none',
});

function Logo() {
  return <img height={22} src={require('./logo-no-slogan-dark.svg')} />;
}

function Contact() {
  return (
    <>
      <Text css={text}>Hotline: 0899 183 193</Text>
      <Text css={text}>Email: hotro@viec.co</Text>
      <Text css={text}>Địa chỉ: Số 17 Đường số 8, Phường 12, Quận 10, TP. Hồ Chí Minh</Text>
    </>
  );
}

const mobileHide = css({
  display: ['none', 'block'],
});

function Footer() {
  return (
    <Box color="#898f9a" bg="#edeef0">
      <Container sx={{ display: 'flex', flexDirection: 'column' }}>
        <Base
          action="Tìm việc làm"
          href="/freelancer"
          image={require('./Find/find-employer.png')}
          imageWebp={require('./Find/find-employer.png?webp')}
          title="Bạn cần tìm việc làm thời gian linh hoạt?"
        />
        <SectionProvider initialKey="company">
          <Grid
            sx={{
              paddingTop: 60,
              paddingBottom: 55,
              gridTemplateColumns: ['repeat(1,1fr)', '4fr 1fr repeat(3,2fr)'],
            }}
          >
            <Box css={mobileHide}>
              <Logo />
              <Contact />
            </Box>
            <div />
            <Section name="company" title="Công ty">
              <Link href="/ve-chung-toi">
                <a>Về chúng tôi</a>
              </Link>
              <Link href="/bai-viet">
                <a>Blog</a>
              </Link>
              <Link href="/tuyen-dung">
                <a>Tuyển dụng</a>
              </Link>
              <Link href="/employer/register">
                <a>Hợp tác cùng Việc Có</a>
              </Link>
            </Section>
            <Section name="policies" title="Chính sách">
              <Link href="/chinh-sach-bao-mat">
                <a>Chính sách bảo mật</a>
              </Link>
              <Link href="/chinh-sach-hop-tac">
                <a>Chính sách hợp tác</a>
              </Link>
              <Link href="/quy-che-hoat-dong-san-tmdt-viec-co">
                <a>Thỏa thuận sử dụng</a>
              </Link>
            </Section>
            <Section name="apps" title="Tải ứng dụng">
              <SafeA href="https://viec.app">App cho cộng tác viên</SafeA>
              <SafeA href="https://viec.app.link">App cho doanh nghiệp</SafeA>
              <BoCongThuong mt={20} css={mobileHide} />
            </Section>
          </Grid>
        </SectionProvider>
        <Box
          sx={{
            borderTop: '1px solid #d5dae1',
            display: ['block', 'none'],
            paddingTop: 15,
            paddingBottom: 20,
          }}
        >
          <Contact />
          <BoCongThuong mt={15} />
        </Box>
        <Flex
          sx={{
            borderTop: '1px solid #d5dae1',
            display: 'flex',
            flexDirection: ['column-reverse', 'row'],
            justifyContent: 'space-between',
            paddingBottom: [30, 20],
            paddingTop: [30, 10],
          }}
        >
          <Box
            sx={{
              fontSize: '16px',
              lineHeight: '28px',
            }}
          >
            © 2019 Made with <img src={require('./heart.svg')} /> by Viec.co
          </Box>
          <Box sx={{ display: 'flex', marginBottom: [30, 0] }}>
            <Social href="https://fb.com/vieccongay" icon={require('./icon-facebook.svg')} />
            <div style={{ width: '20px' }} />
            <Social
              href="https://youtube.com/channel/UCAjADmnli7XEPXUy1ndd6sg"
              icon={require('./icon-youtube.svg')}
            />
          </Box>
        </Flex>
      </Container>
    </Box>
  );
}

export default Footer;
