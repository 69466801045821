import styled from '@emotion/styled';

import rem from 'utils/rem';

const NavSeparator = styled.span`
  flex: 0 0 auto;
  margin: 0 ${rem(15)};

  background: currentColor;
  opacity: 0.35;
`;

export default NavSeparator;
