import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { Button, Container, Text } from 'theme-ui'
import Background, { Props } from './Background'
import Jumper from './Jumper'

const title = css`
  font-size: 34px;
  font-weight: bold;
  line-height: 46px;
  text-transform: uppercase;
`

const Tagline = styled.span`
  font-size: 16px;
  line-height: 24px;
  margin-top: 10px;
`

const container = css`
  color: white;
  display: flex;
  flex-direction: column;
  padding-top: 240px;
  padding-bottom: 240px;

  position: absolute;
  left: 0;
  right: 0;
  top: 0;
`

function Banner(props: Props) {
  return (
    <Background
      image={props.image}
      imageWebp={props.imageWebp}
    >
      <Container css={container}>
        <Text css={title} variant="landing">CẦN NGƯỜI LÀM<br/>VIỆC.CÓ NGAY</Text>
        <Tagline>Tiếp cận nhanh chóng với 40,000+ nhân sự sẵn<br/>sàng làm việc, mọi thủ tục được Việc Có hỗ trợ</Tagline>
        <Button
          css={{ width: '16.67%' }}
          mt={40}
          variant='primary'
        >
          Đăng kí dùng thử
        </Button>
        <Jumper href="#next">Kéo xuống để xem thêm</Jumper>
      </Container>
    </Background>
  )
}

export default Banner
