import { css } from '@emotion/react'
import styled from '@emotion/styled'

export type Props = React.PropsWithChildren<{
  image: string
  imageWebp?: string
}>

const Picture = styled.picture`
  > img {
    height: 100vh;
    min-height: 664px;
    object-fit: cover;
    object-position: top;
    width: 100%;
  }
`

function Background(props: Props) {
  return (
    <div>
      <Picture>
        <source srcSet={props.imageWebp} type="image/webp"/>
        <img src={props.image}/>
      </Picture>
      {props.children}
    </div>
  )
}

export default Background