import { createContext, useCallback, useContext, ReactNode } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { css as cssTheme, Box, Text } from 'theme-ui';
import Collapse from './Collapse';

export const Context = createContext({
  activeKey: null,
  enableCollapser: false,
  onActive: (name: string) => null,
});

type ArrowProps = {
  isOpened: boolean;
};

const Arrow = styled.img<ArrowProps>`
  height: 16px;
  width: 16px;

  transform-origin: 50% 50%;
  transition: transform 300ms ease;
  ${(props) =>
    props.isOpened
      ? css`
          transform: rotate(-180deg);
        `
      : null}
`;

const StyledCollapse = styled(Collapse)(
  cssTheme({
    transition: 'height 300ms',
    '> .ReactCollapse--content': {
      display: 'flex',
      flexDirection: 'column',
      '> a': {
        color: '#898F9A',
        fontSize: [20, 14],
        lineHeight: ['32px', '24px'],
        marginTop: 15,
        textDecoration: 'none',
      },
    },
  }),
);

const Header = styled(Text)(
  cssTheme({
    alignTtems: 'center',
    color: '#2f353f',
    display: 'flex',
    fontSize: [24, 18],
    fontWeight: 'bold',
    justifyContent: 'space-between',
    marginBottom: 15,
  }),
);

type Props = React.PropsWithChildren<{
  name: string;
  title: ReactNode;
}>;

function Section(props: Props) {
  const { activeKey, enableCollapser, onActive } = useContext(Context);
  const { children, name, title } = props;
  const isOpened = !enableCollapser || name === activeKey;
  const handlePress = useCallback(() => {
    if (enableCollapser) {
      onActive(name);
    }
  }, [enableCollapser, onActive, name]);

  return (
    <Box>
      <Header onClick={handlePress}>
        {title}
        {enableCollapser && <Arrow src={require('./arrow-down.svg')} isOpened={isOpened} />}
      </Header>
      <StyledCollapse isOpened={isOpened}>{children}</StyledCollapse>
    </Box>
  );
}

export default Section;
