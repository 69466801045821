import invariant from 'invariant';
import { useEffect, useState, ReactNode, ReactElement } from 'react';

type Props = {
  children: (props: { isScrolled: boolean }) => ReactElement
}

export default function WithIsScrolled(props: Props) {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    invariant(
      typeof props.children === 'function',
      'The children prop is expected to be a function',
    );

    const onScroll = () => {
      setIsScrolled((window.pageYOffset || document.body.scrollTop) > 0);
    };

    // Learn more about how { passive: true } improves scrolling performance
    // https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#Improving_scrolling_performance_with_passive_listeners
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll, {
      // @ts-ignore
      passive: true,
    });
  }, []);

  return props.children({ isScrolled });
}
